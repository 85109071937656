<template>
  <div>
    <a-form :form="form" @submit="handleSubmit">
      <a-row :gutter="20">
        <a-col :span="12">
          <a-form-item class="travel-input-outer">
            <label :class="'filled'"> Título da Oportunidade </label>
            <a-input
              class="travel-input"
              placeholder="Escreva"
              v-decorator="[
                `title`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
            >
              <a-icon slot="prefix" type="field-svg" />
            </a-input>
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item class="travel-input-outer">
            <label :class="'filled'"> Funil </label>
            <a-select
              class="travel-input"
              placeholder="Selecione"
              optionFilterProp="txt"
              @change="onSelectFunnel"
              v-decorator="[
                `funnel_id`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
              style="width: 100%"
            >
              <a-select-option
                v-for="(item, index) of opportunityFunnels.list"
                :key="index"
                :value="item.id"
                :txt="item.name"
              >
                {{ item.id }} - {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item class="travel-input-outer">
            <label :class="'filled'"> Responsável pela oportunidade </label>
            <a-select
              class="travel-input"
              placeholder="Selecione"
              optionFilterProp="txt"
              v-decorator="[
                `user_id`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
              style="width: 100%"
            >
              <a-select-option
                v-for="(item, index) of user.list"
                :key="index"
                :value="item.id"
                :txt="item.first_name"
              >
                {{ item.id }} - {{ item.first_name }} {{ item.last_name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item class="travel-input-outer">
            <label :class="'filled'">Origem da oportunidade </label>
            <a-select
              class="travel-input"
              placeholder="Selecione"
              optionFilterProp="txt"
              v-decorator="[
                `origin`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
              style="width: 100%"
            >
              <a-select-option
                v-for="(item, index) of originsList"
                :key="index"
                :value="item.name"
                :txt="item.name"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="7">
          <a-form-item class="travel-input-outer">
            <label :class="'filled'"> Nome do cliente </label>
            <a-input
              class="travel-input"
              placeholder="Escreva"
              v-decorator="[
                'customer_name',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
            >
              <a-icon slot="prefix" type="field-svg" />
            </a-input>
          </a-form-item>
        </a-col>

        <a-col :span="1">
          <a-popover
            v-model="onClickOpenSearchCustomers"
            trigger="click"
            title="Pesquisar clientes"
          >
            <template slot="content">
              <a-form-item
                class="travel-input-outer mt-5 mb-10"
                style="width: 300px"
              >
                <a-auto-complete
                  :data-source="
                    customers.list.map(
                      ({
                        first_name,
                        last_name,
                        person_type,
                        trading_name,
                        id,
                      }) => ({
                        value: id,
                        text: `${id} - ${
                          person_type == 'Pessoa Física'
                            ? first_name.toUpperCase() +
                              ' ' +
                              last_name.toUpperCase()
                            : trading_name.toUpperCase()
                        }`,
                      })
                    )
                  "
                  style="width: 100%"
                  placeholder="ID, Nome, Nome Fantasia..."
                  v-model="customers.filters.customerName"
                  @search="searchCustomers"
                  @select="onCustomerSelected"
                >
                  <a-input
                    v-decorator="[`customer_id`]"
                    style="border-radius: 0"
                  />
                </a-auto-complete>
              </a-form-item>
            </template>

            <a-icon
              type="search"
              class="f18 relative c-pointer cprimary"
              style="top: 13px; left: -10px"
            />
          </a-popover>
        </a-col>

        <a-col :span="8">
          <a-form-item class="travel-input-outer">
            <label :class="'filled'"> E-mail </label>
            <a-input
              class="travel-input"
              placeholder="Escreva"
              v-decorator="[
                'unique_id',
                {
                  rules: [
                    {
                      type: 'email',
                      message: 'Escreva um e-mail válido',
                    },
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
            >
              <a-icon slot="prefix" type="field-svg" />
            </a-input>
          </a-form-item>
        </a-col>

        <a-col :span="8">
          <a-form-item class="travel-input-outer">
            <label :class="'filled'"> Celular </label>
            <a-input
              class="travel-input"
              placeholder="Escreva"
              v-mask="'(##) # ####-####'"
              v-decorator="[
                'mobile_phone',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório.',
                    },
                    {
                      required: true,
                      min: 16,
                      message: 'Preencha todos números',
                    },
                  ],
                },
              ]"
            >
              <a-icon slot="prefix" type="field-svg" />
            </a-input>
          </a-form-item>
        </a-col>

        <a-col :span="7">
          <a-form-item class="travel-input-outer">
            <label :class="'filled'">Valor (opcional)</label>

            <a-input
              class="travel-input"
              placeholder="Insira o valor"
              v-currency
              v-decorator="[
                `value`,
                {
                  rules: [
                    {
                      required: false,
                      message: 'Preencha o valor.',
                    },
                  ],
                },
              ]"
            >
              <a-icon slot="prefix" type="field-svg" />
            </a-input>
          </a-form-item>
        </a-col>

        <a-col class="mb-20" :span="24">
          <div class="step-name">
            Etapa do funil:
            <b>{{
              opportunityStep.selected.name
                ? opportunityStep.selected.name
                : "Escolha uma etapa"
            }}</b>
          </div>
          <div class="steps">
            <div
              v-for="(item, index) in opportunitiesFunnelSteps.list"
              :key="index"
              class="step"
              :class="theStepClass(index)"
              @mouseover="onHoverStep(item, index)"
              @mouseleave="onMouseLeaveStep(item, index)"
              @click="onClickStep(item, index)"
            >
              {{ item.name }}
            </div>
          </div>
        </a-col>

        <a-col
          v-if="opportunityStep.selected.allow_create_lead == 1"
          class="a-center mt-10"
          :span="24"
        >
          <a-button
            type="primary"
            html-type="submit"
            :loading="loadingCreateOpportunity"
            @click="createAndOpenOpportunity = false"
          >
            <img
              class="mr-10"
              src="@/assets/images/dashboard/opportunities/saved.png"
              width="10"
            />
            Criar
          </a-button>
          <a-button
            class="ml-10"
            type="primary"
            html-type="submit"
            :loading="loadingCreateOpportunity"
            @click="createAndOpenOpportunity = true"
          >
            <img
              class="mr-10"
              src="@/assets/images/dashboard/opportunities/eye.png"
              width="15"
            />
            Criar e abrir
          </a-button>
        </a-col>

        <a-col
          v-if="opportunityStep.selected.allow_create_lead == 0"
          class=""
          :span="24"
        >
          <a-alert
            class="f12"
            message=" Não é permitido criar oportunidade nesta etapa."
            banner
            type="info"
          />
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>

<script>
// libs
import _ from "lodash";

import userMixins from "@/mixins/user/userMixins.js";
import opportunityFunnelsMixins from "../mixins/opportunityFunnelsMixins";
import opportunitiesFunnelStepsMixins from "../mixins/opportunitiesFunnelStepsMixins";
import customerMixins from "@/components/customers/mixins/customerMixins";

export default {
  name: "CreateOpportunityModal",
  props: {
    funnelId: String,
  },
  mixins: [
    userMixins,
    opportunityFunnelsMixins,
    opportunitiesFunnelStepsMixins,
    customerMixins,
  ],
  data() {
    return {
      form: this.$form.createForm(this),
      loadingCreateOpportunity: false,
      createAndOpenOpportunity: false,
      onClickOpenSearchCustomers: false,
      opportunityStep: {
        hover: "",
        hoverIndex: -1,
        selected: "",
        selectedIndex: -1,
      },
      originsList: [],
    };
  },
  mounted() {
    this.user.filters.status.selected = "1";
    this.user.pagination.perPage = 500;
    this.getUsers();

    this.opportunityFunnels.filters.orderBy = "funnel_order";
    this.opportunityFunnels.filters.order = "ascend";
    this.getOpportunityFunnels();

    setTimeout(() => {
      this.form.setFieldsValue({
        funnel_id: this.funnelId,
        user_id: this.$store.state.userData.id,
      });

      this.onSelectFunnel(this.funnelId);
    }, 100);

    this.$http
      .get(`/category-entry/list?page=1&category_id=26&per_page=300`)
      .then(({ data }) => {
        this.originsList = data.data;
      });
  },
  methods: {
    onClickStep(item, index) {
      this.opportunityStep.selectedIndex = index;
      this.opportunityStep.selected = item;

      // tempOpportunity.funnel_step.allow_create_lead
    },
    onMouseLeaveStep() {
      this.opportunityStep.hoverIndex = -1;
      this.opportunityStep.hover = -1;
    },
    onHoverStep(item, index) {
      this.opportunityStep.hover = item;
      this.opportunityStep.hoverIndex = index;
    },
    onSelectFunnel(id) {
      this.opportunitiesFunnelSteps.filters.funnelId = id;
      this.opportunitiesFunnelSteps.filters.status.selected = ["Ativado"];
      this.getOpportunitiesFunnelSteps();
      this.opportunityStep = {
        hover: "",
        hoverIndex: -1,
        selected: "",
        selectedIndex: -1,
      };
    },
    theStepClass(index) {
      let theClass = "";
      if (index <= this.opportunityStep.hoverIndex) theClass = "hover";
      if (index <= this.opportunityStep.selectedIndex) theClass += " active";
      return theClass;
    },
    searchCustomers: _.debounce(function (e) {
      if (e.length > 1) {
        this.getCustomers();
      }
    }, 600),
    onCustomerSelected(customerId) {
      this.form.setFieldsValue({
        [`customer_id`]: customerId ? customerId : 0,
      });

      this.getCustomer(customerId);
      setTimeout(() => {
        let customerName = "";

        if (this.customers.details.person_type === "Pessoa Física") {
          customerName = `${this.customers.details.first_name} ${this.customers.details.last_name}`;
        } else {
          customerName = `${this.customers.details.trading_name}`;
        }

        this.form.setFieldsValue({
          mobile_phone: this.customers.details.mobile_phone,
          customer_name: customerName,
          unique_id: this.customers.details.email,
        });
      }, 200);
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        console.log(err, values);
        if (!err) {
          if (this.opportunityStep.selected.name != undefined) {
            this.loadingCreateOpportunity = true;
            values.step = this.opportunityStep.selected.name;
            //values.customer_name = `${this.customers.details.first_name} ${this.customers.details.last_name}`;
            values.email = values.unique_id;
            values.situation = "Nenhuma";
            values.status = "Aberto";
            values.value = values.value ? values.value.replace(",", ".") : 0;
            values.modified_by = {
              name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
              id: this.$store.state.userData.id,
            };
            this.$http
              .post("/opportunity/create", values)
              .then(({ data }) => {
                this.$message.success(data.message);

                this.loadingCreateOpportunity = false;

                this.$emit("loadOpportunitiesList");

                if (this.createAndOpenOpportunity)
                  this.$router.push(`/opportunity/edit/${data.id}`);

                this.$http.post("/log/create", {
                  user_id: this.$store.state.userData.id,
                  module_id: data.id,
                  module: "opportunity",
                  action: "create-opportunity",
                  description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} criou uma nova oportunidade ID ${data.id} para etapa <b> ${this.opportunityStep.selected.name}</b>.`,
                });
              })
              .catch(({ response }) => {
                this.$message.error(response.data.message);
                this.loadingCreateOpportunity = false;
              });
          } else {
            this.$message.warning("Selecione uma etapa do funil.");
          }
        }
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.step-name
  margin-bottom: 5px
  font-size: 12px
  color: #333
.steps
  display: flex
  align-items: center
  justify-content: center
  align-content: center
  .step.active
    background: #be4178
  .step.active:before
    background: #be4178
  .step.hover
    background: #aaa
  .step.hover:before
    background: #aaa
  .step:before
    width: 30px
    height: 30px
    position: absolute
    right: -14px
    top: 2px
    border-top: 6px solid #FFF
    border-right: 6px solid #FFF
    -webkit-transform: scaleX(0.3) rotate(45deg)
    -moz-transform: scaleX(0.3) rotate(45deg)
    -ms-transform: scaleX(0.3) rotate(45deg)
    -o-transform: scaleX(0.3) rotate(45deg)
    transform: scaleX(0.3) rotate(45deg)
    content: " "
    background: #ccc
    cursor: pointer
    z-index: 1
  .step
    position: relative
    flex: 1
    height: 34px
    cursor: pointer
    text-align: center
    padding: 2px 6px
    font-size: 8px
    color: #FFF
    background: #ccc
    vertical-align: middle
    border-radius: 4px
</style>
