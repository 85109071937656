var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-row',{attrs:{"gutter":20}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v(" Título da Oportunidade ")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              `title`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]),expression:"[\n              `title`,\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Obrigatório',\n                  },\n                ],\n              },\n            ]"}],staticClass:"travel-input",attrs:{"placeholder":"Escreva"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v(" Funil ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              `funnel_id`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]),expression:"[\n              `funnel_id`,\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Obrigatório',\n                  },\n                ],\n              },\n            ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Selecione","optionFilterProp":"txt"},on:{"change":_vm.onSelectFunnel}},_vm._l((_vm.opportunityFunnels.list),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id,"txt":item.name}},[_vm._v("\n              "+_vm._s(item.id)+" - "+_vm._s(item.name)+"\n            ")])}),1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v(" Responsável pela oportunidade ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              `user_id`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]),expression:"[\n              `user_id`,\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Obrigatório',\n                  },\n                ],\n              },\n            ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Selecione","optionFilterProp":"txt"}},_vm._l((_vm.user.list),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id,"txt":item.first_name}},[_vm._v("\n              "+_vm._s(item.id)+" - "+_vm._s(item.first_name)+" "+_vm._s(item.last_name)+"\n            ")])}),1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v("Origem da oportunidade ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              `origin`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]),expression:"[\n              `origin`,\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Obrigatório',\n                  },\n                ],\n              },\n            ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Selecione","optionFilterProp":"txt"}},_vm._l((_vm.originsList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.name,"txt":item.name}},[_vm._v("\n              "+_vm._s(item.name)+"\n            ")])}),1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":7}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v(" Nome do cliente ")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'customer_name',
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]),expression:"[\n              'customer_name',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Obrigatório',\n                  },\n                ],\n              },\n            ]"}],staticClass:"travel-input",attrs:{"placeholder":"Escreva"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":1}},[_c('a-popover',{attrs:{"trigger":"click","title":"Pesquisar clientes"},model:{value:(_vm.onClickOpenSearchCustomers),callback:function ($$v) {_vm.onClickOpenSearchCustomers=$$v},expression:"onClickOpenSearchCustomers"}},[_c('template',{slot:"content"},[_c('a-form-item',{staticClass:"travel-input-outer mt-5 mb-10",staticStyle:{"width":"300px"}},[_c('a-auto-complete',{staticStyle:{"width":"100%"},attrs:{"data-source":_vm.customers.list.map(
                    ({
                      first_name,
                      last_name,
                      person_type,
                      trading_name,
                      id,
                    }) => ({
                      value: id,
                      text: `${id} - ${
                        person_type == 'Pessoa Física'
                          ? first_name.toUpperCase() +
                            ' ' +
                            last_name.toUpperCase()
                          : trading_name.toUpperCase()
                      }`,
                    })
                  ),"placeholder":"ID, Nome, Nome Fantasia..."},on:{"search":_vm.searchCustomers,"select":_vm.onCustomerSelected},model:{value:(_vm.customers.filters.customerName),callback:function ($$v) {_vm.$set(_vm.customers.filters, "customerName", $$v)},expression:"customers.filters.customerName"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([`customer_id`]),expression:"[`customer_id`]"}],staticStyle:{"border-radius":"0"}})],1)],1)],1),_vm._v(" "),_c('a-icon',{staticClass:"f18 relative c-pointer cprimary",staticStyle:{"top":"13px","left":"-10px"},attrs:{"type":"search"}})],2)],1),_vm._v(" "),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v(" E-mail ")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'unique_id',
              {
                rules: [
                  {
                    type: 'email',
                    message: 'Escreva um e-mail válido',
                  },
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]),expression:"[\n              'unique_id',\n              {\n                rules: [\n                  {\n                    type: 'email',\n                    message: 'Escreva um e-mail válido',\n                  },\n                  {\n                    required: true,\n                    message: 'Obrigatório',\n                  },\n                ],\n              },\n            ]"}],staticClass:"travel-input",attrs:{"placeholder":"Escreva"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v(" Celular ")]),_vm._v(" "),_c('a-input',{directives:[{name:"mask",rawName:"v-mask",value:('(##) # ####-####'),expression:"'(##) # ####-####'"},{name:"decorator",rawName:"v-decorator",value:([
              'mobile_phone',
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório.',
                  },
                  {
                    required: true,
                    min: 16,
                    message: 'Preencha todos números',
                  },
                ],
              },
            ]),expression:"[\n              'mobile_phone',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Obrigatório.',\n                  },\n                  {\n                    required: true,\n                    min: 16,\n                    message: 'Preencha todos números',\n                  },\n                ],\n              },\n            ]"}],staticClass:"travel-input",attrs:{"placeholder":"Escreva"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":7}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v("Valor (opcional)")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency"},{name:"decorator",rawName:"v-decorator",value:([
              `value`,
              {
                rules: [
                  {
                    required: false,
                    message: 'Preencha o valor.',
                  },
                ],
              },
            ]),expression:"[\n              `value`,\n              {\n                rules: [\n                  {\n                    required: false,\n                    message: 'Preencha o valor.',\n                  },\n                ],\n              },\n            ]"}],staticClass:"travel-input",attrs:{"placeholder":"Insira o valor"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{staticClass:"mb-20",attrs:{"span":24}},[_c('div',{staticClass:"step-name"},[_vm._v("\n          Etapa do funil:\n          "),_c('b',[_vm._v(_vm._s(_vm.opportunityStep.selected.name
              ? _vm.opportunityStep.selected.name
              : "Escolha uma etapa"))])]),_vm._v(" "),_c('div',{staticClass:"steps"},_vm._l((_vm.opportunitiesFunnelSteps.list),function(item,index){return _c('div',{key:index,staticClass:"step",class:_vm.theStepClass(index),on:{"mouseover":function($event){return _vm.onHoverStep(item, index)},"mouseleave":function($event){return _vm.onMouseLeaveStep(item, index)},"click":function($event){return _vm.onClickStep(item, index)}}},[_vm._v("\n            "+_vm._s(item.name)+"\n          ")])}),0)]),_vm._v(" "),(_vm.opportunityStep.selected.allow_create_lead == 1)?_c('a-col',{staticClass:"a-center mt-10",attrs:{"span":24}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.loadingCreateOpportunity},on:{"click":function($event){_vm.createAndOpenOpportunity = false}}},[_c('img',{staticClass:"mr-10",attrs:{"src":require("@/assets/images/dashboard/opportunities/saved.png"),"width":"10"}}),_vm._v("\n          Criar\n        ")]),_vm._v(" "),_c('a-button',{staticClass:"ml-10",attrs:{"type":"primary","html-type":"submit","loading":_vm.loadingCreateOpportunity},on:{"click":function($event){_vm.createAndOpenOpportunity = true}}},[_c('img',{staticClass:"mr-10",attrs:{"src":require("@/assets/images/dashboard/opportunities/eye.png"),"width":"15"}}),_vm._v("\n          Criar e abrir\n        ")])],1):_vm._e(),_vm._v(" "),(_vm.opportunityStep.selected.allow_create_lead == 0)?_c('a-col',{attrs:{"span":24}},[_c('a-alert',{staticClass:"f12",attrs:{"message":" Não é permitido criar oportunidade nesta etapa.","banner":"","type":"info"}})],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }